import {
  addGameToStats,
  createStatistics,
  GroupMember,
  GroupMembersAdd,
  mergeStates,
  Party,
  Player,
  Players,
  PlayerSittingOrderPatch,
  PlayersPatch,
  RoundDetailsLoaded,
  RoundsAdd,
  Statistics,
} from '@doko/common';
import {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useSortedGames} from './Games';
import {useGroupMembers} from './GroupMembers';
import {LoguxDispatch} from './Logux';
import {createReducer} from './Reducer';
import {useRound} from './Rounds';
import {State} from './Store';

const {addReducer, combinedReducer} = createReducer<Players>({}, 'players');

addReducer<GroupMembersAdd>('groupMembers/add', (state, {newRoundPlayer}) => {
  if (newRoundPlayer && state[newRoundPlayer.roundId]) {
    return {
      ...state,
      [newRoundPlayer.roundId]: [...state[newRoundPlayer.roundId]!, newRoundPlayer],
    };
  }
  return state;
});

addReducer<RoundsAdd>('rounds/add', (state, {round, players}) => ({...state, [round.id]: players}));

addReducer<RoundDetailsLoaded>('roundDetails/loaded', (state, {roundId, players}) => ({
  ...state,
  [roundId]: players,
}));

addReducer<PlayerSittingOrderPatch>('players/patchSittingOrder', (state, {roundId, order}) => {
  const oldPlayers = state[roundId];
  if (oldPlayers) {
    const players = order.map((memberId, index) => {
      const player = oldPlayers.find(({groupMemberId}) => groupMemberId === memberId);
      if (!player) {
        throw new Error(`Missing player '${memberId}' in loaded round players`);
      }
      return {...player, sittingOrder: index + 1};
    });
    return {...state, [roundId]: players};
  }
  return state;
});

addReducer<PlayersPatch>('players/patch', (state, {roundId, groupMemberId, player}) => {
  const prevPlayers = state[roundId];
  if (prevPlayers) {
    const players = [...prevPlayers];
    const playerIdx = players.findIndex(({groupMemberId: memberId}) => groupMemberId === memberId);
    if (playerIdx > -1) {
      players[playerIdx] = mergeStates<Player>(players[playerIdx]!, player);
      return {...state, [roundId]: players};
    }
  }
  return state;
});

export const playersReducer = combinedReducer;

export const playersSelector = (state: State) => state.players;

const emptyPlayers: Player[] = [];

export function usePlayers(): Player[] {
  const {roundId} = useParams<{roundId: string}>();
  return useSelector(playersSelector)[roundId ?? ''] || emptyPlayers;
}

/**
 * Includes players that already left the round
 */
export function useRoundParticipatingPlayers(): Player[] {
  const players = usePlayers();
  return useMemo(() => players.filter((p) => p.leftAfterGameNumber !== 0), [players]);
}

export interface PlayerStats {
  dutySoloPlayed: boolean;
  member: GroupMember;
  player: Player;
  pointBalance: number;
  pointDiffToTopPlayer: number;
  statistics: Statistics;
}

export function usePlayersWithStats(full = false): PlayerStats[] {
  const players = useRoundParticipatingPlayers();
  const games = useSortedGames();
  const members = useGroupMembers();

  return useMemo(() => {
    const statsByMember = new Map<string, PlayerStats>();

    players.forEach((player) => {
      statsByMember.set(player.groupMemberId, {
        player,
        dutySoloPlayed: false,
        member: members[player.groupMemberId]!,
        pointBalance: 0,
        pointDiffToTopPlayer: 0,
        statistics: createStatistics(),
      });
    });

    const addPoints = (p: Party) => {
      p.members.forEach((id) => {
        statsByMember.get(id)!.pointBalance += p.totalPoints;
      });
    };

    games.forEach((game) => {
      const {
        data: {isComplete, gameType, re, contra, manualInput, penaltyCountsAsDutySolo},
      } = game;
      if (!isComplete) {
        //only complete games are counted for the player stats.
        return;
      }

      if (gameType === 'dutySolo' || gameType === 'forcedSolo') {
        statsByMember.get(re.members[0]!)!.dutySoloPlayed = true;
      }

      if (gameType === 'penalty' && penaltyCountsAsDutySolo && contra.members.length === 1) {
        statsByMember.get(contra.members[0]!)!.dutySoloPlayed = true;
      }

      if (gameType === 'manualInput' && manualInput) {
        manualInput.points.forEach(({player, points}) => {
          statsByMember.get(player)!.pointBalance += points;
        });
      }

      addPoints(re);
      addPoints(contra);

      if (full) {
        addGameToStats(game.data, statsByMember);
      }
    });

    const sorted = [...statsByMember.values()].sort((a, b) => b.pointBalance - a.pointBalance);
    const topPoints = sorted.length ? sorted[0]!.pointBalance : 0;
    sorted.forEach((stat) => {
      stat.pointDiffToTopPlayer = topPoints - stat.pointBalance;
    });
    return sorted;
  }, [full, games, members, players]);
}

export function usePatchSittingOrder() {
  const round = useRound()!;
  const dispatch = useDispatch<LoguxDispatch>();
  return useCallback(
    (order: string[]) => {
      if (round.endDate) {
        return;
      }
      dispatch.sync<PlayerSittingOrderPatch>({
        order,
        roundId: round.id,
        type: 'players/patchSittingOrder',
      });
    },
    [dispatch, round],
  );
}

export function usePatchAttendance() {
  const round = useRound()!;
  const roundId = round.id;
  const dispatch = useDispatch<LoguxDispatch>();
  const players = usePlayers();
  const games = useSortedGames();
  return useCallback(
    (groupMemberId: string) => {
      const player = players.find(({groupMemberId: memberId}) => groupMemberId === memberId);
      if (!player) {
        throw new Error(`Missing player '${groupMemberId}' in loaded round players`);
      }
      if (round.endDate) {
        return;
      }
      const isAttending = player.leftAfterGameNumber === null;
      const currentGameNumber = games.length ? games[games.length - 1]!.gameNumber : 0;
      dispatch.sync<PlayersPatch>({
        groupMemberId,
        roundId,
        player: {
          joinedAfterGameNumber: isAttending ? player.joinedAfterGameNumber : currentGameNumber,
          leftAfterGameNumber: isAttending ? currentGameNumber : null,
        },
        type: 'players/patch',
      });
    },
    [dispatch, games, players, round.endDate, roundId],
  );
}
